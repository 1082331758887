import CryptoJS from 'crypto-js';
import * as R from 'ramda';
import { closest } from '@shared/utils/dom';

type DataType = {
  path: string;
  params: Record<string, any>;
};

const trigger = (data?: DataType) => {
  if (!data) return console.error('[Empty data]:', data);

  const { path } = data;
  let { params } = data;
  params = Object.entries({ path, ...params })
    .filter((v: any) => !/__(.+?)__/.test(v[0]) && !R.isNil(v[1]))
    .reduce((a, b) => ({ ...a, [b[0]]: b[1] }), {});
  // TODO: 쇼핑어시스턴트 채팅 sdk를 서비스할 경우 아래 주석을 해제하기 보다는 다른 방법 찾기
  // parent.window.postMessage({ buzzni_shopping_gpt_stat_params: params }, '*');
};

const tracker = (path: string, params: Record<string, any>) => {
  // 암호화 한후 node에 저장

  if (!!params) {
    const tracking = JSON.stringify({ path, params });
    return {
      'data-tracking': CryptoJS.AES.encrypt(
        tracking,
        process.env.NEXT_PUBLIC_AES_KEY ?? ''
      ),
    };
  }
  return {};
};

const handleClick = (e: any) => {
  const el = closest(e.target, 'a, button, [role="button"]');

  if (!!el && !!el.getAttribute('data-tracking') && e.isTrusted) {
    e.preventDefault();

    const data = CryptoJS.AES.decrypt(
      el.getAttribute('data-tracking') || '',
      process.env.NEXT_PUBLIC_AES_KEY ?? ''
    ).toString(CryptoJS.enc.Utf8);

    try {
      const tracking = JSON.parse(data);
      (async () => {
        await trigger(tracking);

        el.dispatchEvent(new e.constructor(e.type, e));
      })();
    } catch (e) {
      /* empty */
    }
  }
};

const initialize = () => {
  document.addEventListener('click', handleClick);
  return () => {
    document.removeEventListener('click', handleClick);
  };
};

export default { initialize, tracker, trigger };
